import React, { KeyboardEventHandler, useEffect, useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Link, LinkButton, LanguageSwitcher } from "@app/components/common";
import { ButtonVariant, LinkButtonType } from "../enums";
import { DownloadAppBar } from "./download-app-bar/DownloadAppBar";
import { MainNav } from "./main-nav/MainNav";

const HEADER_HEIGHT = 80;

export const Header = () => {
  const { t } = useTranslation("mainnav");
  const [menuOpen, setMenuOpen] = useState(false);
  const [navbarSticky, setNavbarSticky] = useState(false);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    setMenuOpen((o) => !o);
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [menuOpen]);

  const handleScroll = () => {
    if (window.scrollY > HEADER_HEIGHT) {
      setNavbarSticky(true);
    } else {
      setNavbarSticky(false);
    }
  };

  useEffect(() => {
    if (window.scrollY > HEADER_HEIGHT) {
      setNavbarSticky(true);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleOnKeyDown: KeyboardEventHandler<HTMLButtonElement> = (event) => {
    if (event.key === "Escape") {
      setMenuOpen(false);
    }
  };

  return (
    <header className="min-h-20">
      <div className="fixed z-[1000] w-full">
        <DownloadAppBar getAppLabel={t("get_app")} />
        <div
          className={classNames(
            "navbar justify-between px-5 py-4.5 duration-300",
            navbarSticky && "bg-base-content",
            "md:pl-7.5 md:pr-6",
            "xl:animate-slide-header-down xl:[animation-duration:1.5s]",
          )}
        >
          <div
            className={classNames(
              "order-3 flex items-center gap-x-5",
              "md:order-1 md:flex-1 md:gap-x-10",
            )}
          >
            <button
              className="flex h-8 w-7 flex-col items-center justify-center p-0"
              onClick={toggleMenu}
              onKeyDown={handleOnKeyDown}
              aria-label="Main menu"
            >
              <div
                className={classNames(
                  "w-full duration-500",
                  "before:my-1.5 before:block before:h-0.5 before:bg-primary-content before:duration-500",
                  "after:my-1.5 after:block after:h-0.5 after:bg-primary-content after:duration-500",
                  {
                    "before:translate-y-2 before:rotate-[135deg] after:-translate-y-2 after:-rotate-[135deg]":
                      menuOpen,
                  },
                )}
              >
                <div
                  className={classNames(
                    "my-1.5 block h-0.5 bg-primary-content duration-500",
                    { "scale-0": menuOpen },
                  )}
                ></div>
              </div>
            </button>
            <div className="hidden md:block">
              <LanguageSwitcher />
            </div>
          </div>
          <div className={"align-center order-2 flex flex-none justify-center"}>
            <Link to="/" onClick={closeMenu}>
              <StaticImage
                src="../../../images/alpian_logo-black.png"
                alt="Alpian logo"
                loading="eager"
                placeholder="blurred"
                layout="fixed"
                width={100}
              />
            </Link>
          </div>
          <div
            className={classNames(
              "order-1 flex w-[27px]",
              "md:order-3 md:w-auto md:flex-1 md:justify-end",
            )}
          >
            <div className="hidden md:block">
              <LinkButton
                to=""
                onClick={closeMenu}
                label={t("cta")}
                type={LinkButtonType.DOWNLOAD_APP}
                variant={ButtonVariant.FILL_PRIMARY_CONTENT}
              />
            </div>
          </div>
        </div>
      </div>

      <MainNav isOpen={menuOpen} onClose={closeMenu} />
    </header>
  );
};
